<template>
  <div class="content">


    <!-- 成果展示分类详情 -->
    <div>
      <div v-if="isshow">
     <div class="contv" v-if="gettype==0">
        <span class="ctitle">学科领域:</span>
         <div class="cspan">
          <el-checkbox class="allspan" :indeterminate="isIndeterminate1" v-model="checkAll1" @change="handleCheckAllChange1">全选</el-checkbox>
          <el-checkbox-group v-model="checkList1" @change="handleCheckedChange1">
            <el-checkbox v-for="item in typeList1" :label="item.code" :value='item.code'>{{item.remark}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>


      <div class="contv">
        <span class="ctitle">应用领域:</span>
        <div class="cspan">
          <el-checkbox class="allspan" :indeterminate="isIndeterminate2" v-model="checkAll2" @change="handleCheckAllChange2">全选</el-checkbox>
          <el-checkbox-group v-model="checkList2" @change="handleCheckedChange2">
            <el-checkbox v-for="item in typeList2" :label="item.code" :value='item.code'>{{item.remark}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="contv" v-if="gettype==0">
        <span class="ctitle">合作方式:</span>
        <div class="cspan">
           <!-- <el-radio-group v-model="checkList3" @change="handleCheckedChange3">
              <el-radio  v-for="item in typeList3" :label="item.code" :value="item.code">{{item.remark}}</el-radio>
            </el-radio-group> -->
            <el-checkbox class="allspan" :indeterminate="isIndeterminate3" v-model="checkAll3" @change="handleCheckAllChange3">全选</el-checkbox>
            <el-checkbox-group v-model="checkList3" @change="handleCheckedChange3">
              <el-checkbox v-for="item in typeList3" :label="item.code" :value='item.code'>{{item.remark}}</el-checkbox>
            </el-checkbox-group>
        </div>
      </div>

      </div>
      <div class="contv">
        <span class="ctitle">关键词:</span>
        <div class="cspan" >
           <el-input
              style="width: 400px;"
              placeholder="请输入关键词搜索"
              prefix-icon="el-icon-search"
              v-model="aName" clearable @clear='clear'>
               <el-button slot="append" icon="el-icon-search" @click="getName"></el-button>
            </el-input>

        </div>
      </div>
      <div class="cbtn" v-if="totalCount>0" @click="tochange">{{isshow?'收起':'展开'}}</div>
    </div>
  </div>


  </div>
</template>

<script>
  import area from '@/utils/area.json'
  import api from '@/config/api'
  import {
    getRequst,
    postRequest
  } from '@/api/index'
  export default {
    props: {
      amType: {
        type: Number,
        default: 0
      },
      gettype: {
        type: Number,
        default: 0
      },
      totalCount: {
        type: Number,
        default: 0
      },
      isaName: {
        type: Boolean,
        default: false,
      },
    },
    watch:{
      amType(val,old){
        if(val!=old&&val){
          this.checkList3=''
          this.isIndeterminate2 = false
          this.$emit('getType3',this.checkList3 )
        }
        if(val!=old){
          this.aName = ''
          this.$emit('getaName',this.aName )
        }
      },
       $route: {
         handler:  function (val, oldVal){
           if(val.name!=oldVal.name){
             this.aName = ''
             this.$emit('getaName',this.aName )
           }
         },
         // 深度观察监听
         deep:  true
       }
    },
    data() {
      return {
        areavalue: '',
        options: area,
        navTitle: '',
        checkList1: [],
        checkList2: [],
        checkList3: [],
        typeList1: [ ],
        typeList2: [],
        typeList3: [],
        cindex: 1,
        checkAll1: false,
        isIndeterminate1: false,
        checkAll2: false,
        isIndeterminate2: false,
        checkAll3: false,
        isIndeterminate3: false,
        isshow:1,
        aName:'',
      }


    },
    mounted() {
      this.getTypeList()
    },
    methods: {
      clear(){
        this.aName = ''
        this.getName()
      },
      getName(){
        this.$emit('getaName',this.aName )
        // if(this.aName){
        //    this.$emit('getaName',this.aName )
        // }
        // else{
        //   this.$message({
        //     type: 'info',
        //     message: '请输入关键词搜索'
        //   });
        // }

      },
      tochange(){
        this.isshow = !this.isshow
      },
      //获取字典列表
      getTypeList() {

        let data1 = {
          page: 1,
          limit: 30,
          filterName: '学科领域'
        }
        getRequst(api.sysTypeList, data1).then(res => {
          if(res.page.list.length>0){
             this.typeList1 = res.page.list
          }
        })


        let data2 = {
          page: 1,
          limit: 30,
          filterName: '应用领域'
        }
        getRequst(api.sysTypeList, data2).then(res => {
         if(res.page.list.length>0){
           this.typeList2 = res.page.list
         }
        })

        let data3 = {
          page: 1,
          limit: 30,
          filterName: '合作方式'
        }
        getRequst(api.sysTypeList, data3).then(res => {
         if(res.page.list.length>0){
            this.typeList3 = res.page.list
         }
        })
      },

       handleCheckAllChange1(val) {
         let allList = [ ]
         this.typeList1.forEach(item=>{
          allList.push(item.code)
         })

         this.checkList1 = val ? allList: [];
          this.isIndeterminate1 = false;
          this.$emit('getType1',this.checkList1 )
        },
        handleCheckedChange1(value) {
          let checkedCount = value.length;
          this.checkAll1 = checkedCount === this.typeList1.length;
          this.isIndeterminate1 = checkedCount > 0 && checkedCount < this.typeList1.length;

           this.$emit('getType1',value)
        },


       handleCheckAllChange2(val) {
          let allList = [ ]
          this.typeList2.forEach(item=>{
           allList.push(item.code)
          })

          this.checkList2 = val ? allList: [];
          this.isIndeterminate2 = false;
           this.$emit('getType2',this.checkList2 )

        },
        handleCheckedChange2(value) {
          let checkedCount = value.length;
          this.checkAll2 = checkedCount === this.typeList2.length;
          this.isIndeterminate2 = checkedCount > 0 && checkedCount < this.typeList2.length;
          this.$emit('getType2',value )
        },

        handleCheckAllChange3(val) {
           let allList = [ ]
           this.typeList3.forEach(item=>{
            allList.push(item.code)
           })

           this.checkList3 = val ? allList: [];
           this.isIndeterminate3 = false;
            this.$emit('getType3',this.checkList3 )

         },
         handleCheckedChange3(value) {
           let checkedCount = value.length;
           this.checkAll3 = checkedCount === this.typeList3.length;
           this.isIndeterminate3 = checkedCount > 0 && checkedCount < this.typeList3.length;
           this.$emit('getType3',value )
         },

         // handleCheckedChange3(value) {
         //   this.$emit('getType3',value )
         // },

      //点击切换分类
      onchange(data) {
        this.cindex = data
      }
    },
  }
</script>

<style lang="less" scoped>
  .cbtn{
        font-size: 14px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #00A0E9;
        line-height: 40px;
        text-align: center;
        position: absolute;
        bottom: 10px;
        right: -50px;
        cursor: pointer;
        z-index: 2;
        background-color: #00A0E9;
        color: #fff;
         }

.el-checkbox {
  margin-right: 20px;
}
  .content {
    width: 1180px;
    margin: 0px auto;
    font-size: 12px;
    text-align: left;
    position: relative;

    .cont {
      height: 40px;
      line-height: 40px;
      margin-bottom: 6px;
      color: #000;
    }

    .conttext {
      height: 40px;
      line-height: 40px;
      background-color: #fff;
      border-bottom: 2px solid #0079cf;
      margin-bottom: 1px;

      span {
        width: 160px;
        text-align: center;
        font-size: 16px;
        font-family: "Microsoft YaHei";
        font-weight: 400;
        border: 1px #0079cf solid;
        line-height: 39px;
        border-bottom: 0;
        display: inline-block;
        cursor: pointer;
      }

      .active {
        background: #0079cf;
        color: #fff;
      }
    }

    .contv {
      display: flex;
      border: 1px #ccc dotted;
      align-items: center;

      .ctitle {
        width: 100px;
        text-align: center;
        font-size: 14px;


      }

      .cspan {
        width: 90%;
        padding: 10px 10px;
        line-height: 20px;
        border-left: 1px #ccc dotted;
        display: flex;
        justify-content: flex-start;
      .allspan{
          margin: 0 10px ;
        }

        span {
          display: inline-block;
          text-align: center;
          padding: 10px 20px;
        }
      }
    }
  }
</style>
